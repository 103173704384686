import React, { FormEventHandler, ReactElement, useEffect } from 'react';

import { Link, useForm } from '@inertiajs/react';
import route from 'ziggy-js';

import Button from '@/Components/Button/Button';
import FormInput from '@/Components/Input/FormInput';
import AuthLayout from '@/Layouts/AuthLayout';
import { useAnalytics } from '@/Analytics/AnalyticsContext';
import { AuthenticatedPageProps } from '@/types';

const Login = () => {
  const { setUser, trackEvent } = useAnalytics();

  const form = useForm({
    email: '',
    password: '',
    remember: false,
  });

  useEffect(() => {
    return () => {
      form.reset('password');
    };
  }, []);

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    form.post(route('login'), {
      onSuccess: (data) => {
        const { auth } = data.props as unknown as AuthenticatedPageProps;

        if (auth.user?.id) {
          setUser(auth.user.id);
          trackEvent('User Logged');
        }
      },
    });
  };

  return (
    <>
      <div className="text-center my-4">
        <h1 className="text-24 font-medium">Connectez-vous</h1>
      </div>

      <form onSubmit={submit}>
        <div className="flex flex-col gap-2">
          <FormInput
            errorText={form.errors.email}
            label="Adresse e-mail"
            onChange={(e) => form.setData('email', e.target.value)}
            value={form.data.email}
          />

          <FormInput
            errorText={form.errors.password}
            label="Mot de passe"
            onChange={(e) => form.setData('password', e.target.value)}
            type="password"
            value={form.data.password}
          />

          <div className="flex justify-between items-center text-base">
            <Link
              className="text-purple-500 hover:underline"
              href={route('password.request')}
            >
              Mot de passe oublié ?
            </Link>
          </div>
        </div>

        <Button className="w-full mt-4" type="submit" variant="primary">
          Se connecter
        </Button>
      </form>

      <div className="text-center mt-3 text-base text-gray-500">
        Pas encore de compte ?{' '}
        <Link
          className="text-purple-500 hover:underline"
          href={route('register')}
        >
          Inscrivez-vous
        </Link>
      </div>
    </>
  );
};

Login.layout = (page: ReactElement) => (
  <AuthLayout title="Connectez-vous">{page}</AuthLayout>
);

export default Login;
